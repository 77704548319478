<template>
  <div>
    <v-form
      style="margin-bottom: 1rem"
      v-model="valid"
      class="position-relative"
    >
      <block v-if="loading"></block>

      <v-row class="ml-2 mr-2">
        <v-col cols="12" class="tabsNh">
          <v-col class="d-flex justify-space-between" style="min-height: 50px">
            <div>
              <v-btn
                v-if="$store.getters['rolesUser/getCustomRole'](41)"
                small
                color="error"
                @click.prevent.stop="confirmdeleteProvider()"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>

            <div>
              <v-btn
                v-if="$store.getters['rolesUser/getCustomRole'](40)"
                small
                class="btnsave"
                @click.prevent.stop="updateProvider"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-col>
        <v-col cols="12" sm="12" md="6" style="margin-top: 1rem">
          <h3>Datos Principales</h3>
          <br />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          style="margin-top: 1rem; display: flex; justify-content: flex-end"
        >
          <v-checkbox
            v-model="form.isglobalbranch"
            label="Es Sucursal Global"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            dense
            :value="form.id_provider"
            label="ID del Proveedor *"
            :readonly="readonly"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providername"
            label="Nombre del Proveedor*"
            required
            :rules="requiredRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providerphone"
            label="Télefono"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providermobilenumber"
            label="Celular *"
            @input="maskNum"
            required
            :rules="requiredRule"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.provideremail"
            label="Correo eléctronico"
            :rules="emailRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providerwebsite"
            label="Sitio Web "
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-autocomplete
            outlined
            dense
            :items="countryList"
            attach
            item-text="name"
            @change="getStates"
            v-model="form.providercountry"
            required
            :rules="requiredRule"
            label="Pais*"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            outlined
            dense
            v-if="form.providercountry == 'El Salvador'"
            :items="departments"
            v-model="form.providerstate"
            label="Estado/Departamento"
            item-text="Valores"
            item-value="Codigo"
            attach
          ></v-autocomplete>
          <v-autocomplete
            outlined
            dense
            v-else
            v-model="form.providerstate"
            label="Estado/Departamento"
            attach
            :items="stateList"
            item-text="name"
            @change="getCities"
          >
            <template v-slot:no-data>
              <div class="px-4">{{ noDataLabelStates }}</div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            outlined
            dense
            :items="municipalities"
            attach
            v-if="form.providercountry == 'El Salvador'"
            :readonly="!form.providerstate"
            v-model="form.providercity"
            label="Ciudad"
            item-text="Valores"
            item-value="Codigo"
          >
          </v-autocomplete>
          <v-autocomplete
            outlined
            dense
            v-else
            v-model="form.providercity"
            label="Ciudad"
            attach
            item-text="name"
            :items="cityList"
            :readonly="!form.providerstate"
          >
            <template v-slot:no-data>
              <div class="px-4">{{ noDataLabelCities }}</div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="3" style="">
          <v-autocomplete outlined dense attach :items="tipoIngresoProveedores" item-text="Valores" item-value="Codigo" v-model="form.tipoingresos" label="Sector">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <h3 class="tabs" @click="showLegal = !showLegal">
            Información Tributaria
          </h3>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: 0.8rem">
          <h3>Datos de Contacto</h3>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providercontactname"
            label="Nombre *"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providercontactemail"
            label="Correo Eléctronico "
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providercontactphone"
            label="Télefono "
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.providercontactmobile"
            label="Celular *"
            @input="maskNum"
            required
            :rules="requiredRule"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            outlined
            dense
            v-model="form.provideraddress"
            label="Dirección "
          ></v-text-field>
        </v-col>

       

        <v-col cols="12">
          <h3
            :class="showBgcolorNotes"
            @click.prevent="showNotes = !showNotes"
            style="text-align: center"
          >
            Comentarios
          </h3>
          <v-dialog
            v-model="showNotes"
            scrollable
            :width="$vuetify.breakpoint.smAndDown ? '90%' : '60%'"
          >
            <v-card class="pa-4" height="inherit">
              <div
                style="
                  margin-top: 0.3rem;
                  margin-bottom: 0.3rem;
                  display: flex;
                  justify-content: flex-end;
                "
              >
                <v-btn
                  small
                  class="btndelete"
                  @click.prevent="showNotes = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <quill-editor
                v-show="showNotes"
                :options="quillOptions"
                :content="form.notes"
                @change="onEditorChange($event)"
              ></quill-editor>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
          v-model="showLegal"
          scrollable
          :width="$vuetify.breakpoint.smAndDown ? '90%' : '60%'"
          class="white"
          height="inherit"
        >
          <v-card class="pa-4" style="overflow-y: auto;" >
            <div
              style="
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                display: flex;
                justify-content: flex-end;
              "
            >
              <v-btn small class="btndelete" @click.prevent="showLegal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-row class="mt-4">
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  dense
                  v-model="form.providerlegalname"
                  label="Nombre Legal "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  dense
                  v-model="form.providerlegaladdress"
                  label="Dirección Legal del Proveedor"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  outlined
                  dense
                  label="Actividad economica:*"
                  :items="activities"
                  @change="changueValueactivity"
                  required
                  :rules="required"
                  attach
                  v-model="form.providerlegalsector"
                  item-text="Valores"
                  item-value="Codigo"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <!--                    <v-text-field-->
                <!--                            v-model="form.providerlegaltaxpayersize"-->
                <!--                            label="Provider Legal Tax Payer Size"-->
                <!--                    ></v-text-field>-->
                <span class="fixing">
                  <v-autocomplete
                    outlined
                    dense
                    :items="size"
                    label="Tamaño del Contribuyente Legal del Proveedor"
                    attach
                    v-model="form.providerlegaltaxpayersize"
                  ></v-autocomplete>
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <!--                    <v-text-field-->
                <!--                            v-model="form.providerlegalentity"-->
                <!--                            label="Provider legal Entity"-->
                <!--                    ></v-text-field>-->
                <span class="fixing">
                  <v-autocomplete
                    outlined
                    dense
                    :items="entity"
                    label="Entidad Jurídica del Proveedor"
                    attach
                    v-model="form.providerlegalentity"
                  ></v-autocomplete>
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  dense
                  v-model="form.providerlegalnrc"
                  label="Proveedor NRC Legal"
                  @keyup="nrcMask"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  outlined
                  dense
                  v-model="form.providerlegalnit"
                  label="Proveedor NIT Legal"
                  @keyup="maskNit"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" style="margin-top: 1.4rem">
                <v-checkbox
                  v-model="form.providerlegalisnontaxable"
                  label="Proveedor Libre de Impuesto"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="4" style="margin-top: 1.4rem">
                <v-checkbox
                  v-model="form.legalagentretain"
                  label="Es agente de retención 1%"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="4" style="margin-top: 1.4rem">
                <v-checkbox
                  v-model="form.legalreterenta"
                  label="Retiene 10% renta"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
  </div>
</template>

<script>
import Vue from "vue";
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapGetters } from "vuex";
import {
  nitForHomologation as nitMask,
  duiForHomologation as duiMask,
  nrc as nrcMask,
  applyDUIMaskOutOfInput,
  applyNITMaskOutOfInput,
  numberSV,
} from "../../../helpers/masks";
import { validateNITHomologation } from "../../../helpers/object";

export default {
  name: "ManageProvider",
  components: { Block, Alerts },
  props: ["data", "win"],
  data() {
    return {
      showLegal: false,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      form: {},
      showBgcolorNotes: "tabs",
      quillOptions: quillConfig,
      showNotes: false,
      entity: ["PERSONA NATURAL", "SOCIEDAD ANÓNIMA", "CORPORACIÓN", "PERSONA JURIDICA"],
      size: ["PEQUEÑO", "MEDIANO", "GRANDE"],
      requiredRule: [(value) => !!value || "El campo es requerido."],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      valid: false,
      loading: false,
      readonly: true,
      stateList: [],
      cityList: [],
      noDataLabelStates: "Seleccione un país",
      noDataLabelCities: "No hay ciudades disponibles",
    };
  },
  computed: {
    ...mapGetters(["getCatalogMH"]),
    tipoIngresoProveedores() {
      return this.getCatalogMH('TipoIngresoProveedores')
    },
    departments() {
      return this.getCatalogMH("Departamento");
    },
    municipalities() {
      const municipalities = this.getCatalogMH("Municipio");
      if (this.form.providerstate) {
        const department = municipalities.filter(
          (mun) => mun.Departamento == this.form.providerstate
        );
        return department;
      }
      return [];
    },
    activities() {
      return this.getCatalogMH("ActividadEconomica");
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
    checkcountry() {
      if (this.form.country == "El Salvador") return true;
      else return false;
    },
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");

    this.form.idbusiness =
      JSON.parse(localStorage.getItem("user")).businessid || "";
    this.form.idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.form.iduserupdate = JSON.parse(localStorage.getItem("user")).id || "";

    if (this.data) {
      Object.keys(this.data).map((el) => {
        console.log(el);
        Vue.set(this.form, el.toLowerCase(), this.data[el]);
      });
    }

    if (this.form.providerlegalnit.replace("-", "").length > 9) {
      this.form.providerlegalnit &&
        (this.form.providerlegalnit = applyNITMaskOutOfInput(
          this.form.providerlegalnit
        ));
    } else {
      this.form.providerlegalnit &&
        (this.form.providerlegalnit = applyDUIMaskOutOfInput(
          this.form.providerlegalnit
        ));
    }

    this.form.providerlegalnrc &&
      (this.form.providerlegalnrc = applyDUIMaskOutOfInput(
        this.form.providerlegalnrc
      ));
    if (this.form.providercountry != "El Salvador") {
      this.getStates();
      this.getCities();
    }
  },
  watch: {
    /*"form.providerlegaltaxpayersize"() {
      if (this.form.providerlegaltaxpayersize == "GRANDE") {
        this.form.legalagentretain = true
      }
      else {
        this.form.legalagentretain = false
      }
    }*/
  },
  methods: {
    async maskNum() {
      if(this.form.providermobilenumber) {
        this.form.providermobilenumber = await numberSV(this, this.form.providermobilenumber)
      }

      if(this.form.providercontactmobile) {
        this.form.providercontactmobile = await numberSV(this, this.form.providercontactmobile)
      }
    },
    async maskNit(e) {
      if (e.which === 8) return false;
      if (this.form.providerlegalnit.replace("-", "").length >= 14) {
        this.form.providerlegalnit = await nitMask(
          this,
          this.form.providerlegalnit
        );
      } else {
        this.form.providerlegalnit = await duiMask(
          this,
          this.form.providerlegalnit
        );
      }
    },
    async nrcMask(e) {
      if (e.which === 8) return false;
      this.form.providerlegalnrc = await nrcMask(
        this,
        this.form.providerlegalnrc
      );
    },
    async getStates() {
      if (this.form && this.form.providercountry == "El Salvador") return [];
      if (this.form.providercountry) {
        this.noDataLabelStates = "Cargando...";
        this.stateList = [];

        this.stateList = await this.$API.general.getStates(
          this.form.providercountry
        );

        this.noDataLabelStates = "No hay Estados disponibles";
      }
    },
    async getCities() {
      if (this.form && this.form.providercountry == "El Salvador") return [];
      console.log(this.form.providerstate);
      if (this.form.providerstate) {
        this.noDataLabelCities = "Cargando...";
        this.cityList = [];

        this.cityList = await this.$API.general.getCities(
          this.form.providerstate
        );
        this.noDataLabelCities = "No hay Ciudades disponibles";
      }
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteprovider") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("addWindow", {
          name: "ProvidersList",
          component: "ProvidersList",
          unique: true,
        });
        this.$store.dispatch("reloadData", "providers");
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteprovider") {
        this.deleteProvider();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    confirmdeleteProvider() {
      this.showAlert(
        "question",
        "Eliminar",
        "¿Seguro que desea eliminar el producto seleccionado?",
        "deleteprovider"
      );
    },
    updateProvider() {
      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Por favor, verifique los campos requeridos"
        );
        return;
      }
      if (this.form.providerlegalnit) {
        if (
          !validateNITHomologation(
            "form",
            this,
            "providerlegalnit",
            "Número de NIT"
          )
        ) {
          return;
        }
      }
      this.loading = true;
      const formdata = new FormData();
      this.form.providerlegalnit = this.form.providerlegalnit.replace(/-/g, "");
      this.form.providerlegalnrc = this.form.providerlegalnrc.replace(/-/g, "");
      let keys = Object.keys(this.form);
      keys.forEach((key) => {
        if (key === "providerlegalisnontaxable") {
          return formdata.append(key, Number(this.form[key]));
        }
        formdata.append(key, this.form[key]);
      });
      this.$API.providers
        .updateProvider(this.form.id_provider, formdata)
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Actualizado",
            "El proveedor ha sido actualizado"
          );
          this.$store.dispatch("reloadData", "providers");
          if (this.form.providerlegalnit.replace("-", "").length > 9) {
            this.form.providerlegalnit &&
              (this.form.providerlegalnit = applyNITMaskOutOfInput(
                this.form.providerlegalnit
              ));
          } else {
            this.form.providerlegalnit &&
              (this.form.providerlegalnit = applyDUIMaskOutOfInput(
                this.form.providerlegalnit
              ));
          }
          this.form.providerlegalnrc &&
            (this.form.providerlegalnrc = applyDUIMaskOutOfInput(
              this.form.providerlegalnrc
            ));
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ocurrio un error al intentar actualizar el proveedor"
          );
          this.loading = false;
        });
    },
    deleteProvider() {
      this.loading = true;
      this.$API.providers
        .logicalDeleteProvider(this.form.id_provider)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.showAlert(
            "success",
            "Eliminado",
            "El proveedor ha sido eliminado",
            "deleteprovider"
          );
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ocurrion un error al eliminar el proveedor"
          );
          this.loading = false;
        });
    },
    onEditorChange(e) {
      this.form.notes = e.html;
      if (this.form.notes != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (this.form.notes == "" || this.form.notes == "<p><br></p>") {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },
  },
};
</script>

<style scoped>
.btnsave {
  background-color: #00a178 !important;
  color: white;
}
.btndelete {
  background-color: #e3191e !important;
  color: white;
}
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}
.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}
</style>
